import { FC, Fragment, useContext, useEffect, useState } from 'react'

import FieldContext from 'rc-field-form/lib/FieldContext'

import { ClientType, ClientTypeTranslate, ProfileType } from '@dltru/dfa-models'
import { Button, DeleteIcon, EMPTY_FIELD_VALUE, Form, WrappedSelect } from '@dltru/dfa-ui'

import api from '@services/api'

import { InnInput } from '@components/Form'

import { InvestorList } from './InvestorsList'
import styles from './style.m.less'

interface IComponentProps {
    isEdit: boolean
    noAvailableInn?: string | number | undefined | null
}

const label = 'Список пользователей'
const listName = 'investors_data'

const clientTypeDescriptionOptions = Object.entries(ClientTypeTranslate).map(([value, label]) => ({
    value,
    label,
}))

export const InvestorsBlock: FC<IComponentProps> = ({ isEdit, noAvailableInn }) => {
    const [currentClientTypes, setCurrentClientTypes] = useState<Map<number, ClientType>>(new Map())
    const { getFieldValue, setFieldsValue } = useContext(FieldContext)

    const investorUuids = getFieldValue('investors')

    const onChangeClientType = (id: number, key: number) => (value: ClientType) => {
        setCurrentClientTypes((data) => {
            data.set(key, value)
            return new Map(data)
        })
        const oldValues = getFieldValue(listName)
        setFieldsValue({
            [listName]: oldValues.map((item, index) =>
                index === id ? { ...item, client_inn: '' } : item,
            ),
        })
    }

    const setEmptyInitialValue = () => {
        setFieldsValue({ [listName]: [{ client_inn: '', client_type: '' }] })
        setCurrentClientTypes(new Map())
    }

    const getInvestorsInitial = async () => {
        const investorsInitial = await api.lib.getProfileShortInfo({ user_uuid: investorUuids })
        if (investorsInitial?.data?.item?.items) {
            setCurrentClientTypes(
                new Map(
                    investorsInitial.data.item.items.map(({ profile_type }, index) => [
                        index,
                        profile_type as ClientType,
                    ]),
                ),
            )
            setFieldsValue({
                [listName]: investorsInitial.data.item.items.map((item) => ({
                    client_inn: item.inn,
                    client_type: item.profile_type,
                })),
            })
        } else {
            setEmptyInitialValue()
        }
    }

    useEffect(() => {
        if (!isEdit && investorUuids?.length) {
            getInvestorsInitial()
        } else if (!isEdit && !investorUuids?.length) {
            setEmptyInitialValue()
        }
    }, [isEdit])

    if (isEdit) {
        const investorUuids = getFieldValue('investors')
        return (
            <div className="ant-row ant-form-item form-item formComponentItem form-item--uneditable">
                <div className="ant-col ant-form-item-label">
                    <label>{label}</label>
                </div>
                <div className="ant-col ant-form-item-control">
                    {investorUuids?.length > 0 ? (
                        <InvestorList investorUuids={investorUuids} />
                    ) : (
                        <div className="ant-form-item-control-input">{EMPTY_FIELD_VALUE}</div>
                    )}
                </div>
            </div>
        )
    }

    return (
        <div className={styles.container}>
            <h4 className={styles.header}>Список пользователей</h4>
            <Form.List name={listName}>
                {(fields, { add, remove }) => (
                    <div className={styles.grid}>
                        {fields.map(({ key, name, ...restField }, index) => {
                            const props = {
                                ...restField,
                                listName,
                                uneditable: isEdit,
                                fieldKey: name,
                            }
                            const currentClientType = currentClientTypes.get(key)
                            return (
                                <Fragment key={key}>
                                    <div className={styles.index}>{index + 1}</div>
                                    <InnInput
                                        noAvailableInn={noAvailableInn}
                                        className={styles.innInput}
                                        placeholder="Укажите ИНН пользователя"
                                        label=""
                                        name={[name, 'client_inn']}
                                        required
                                        isUl={currentClientType === ProfileType.LEGL}
                                        disabled={!currentClientType}
                                        checkInnExistenceFor={currentClientType}
                                        checkInnExistenceText="Пользователь с указанным ИНН не может участвовать в сделках размещения. Если ИНН указан корректно, свяжитесь с пользователем для выяснения его статуса на платформе."
                                        {...props}
                                    />

                                    <WrappedSelect
                                        name={[name, 'client_type']}
                                        required
                                        placeholder="Выберите тип пользователя"
                                        options={clientTypeDescriptionOptions}
                                        onChange={onChangeClientType(name, key)}
                                        {...props}
                                    />
                                    <div>
                                        {fields.length > 1 && (
                                            <Button
                                                type="link"
                                                size="small"
                                                onClick={() => remove(name)}
                                                style={{ padding: '6px 0' }}
                                                className={styles.deleteIcon}
                                            >
                                                <DeleteIcon />
                                            </Button>
                                        )}
                                    </div>
                                </Fragment>
                            )
                        })}
                        <Button
                            type="link"
                            onClick={() => add()}
                            size="small"
                            style={{ marginLeft: 40 }}
                        >
                            Добавить ещё
                        </Button>
                    </div>
                )}
            </Form.List>
        </div>
    )
}
