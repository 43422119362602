import { FC, useContext, useEffect } from 'react'

import { Form, Step, StepperContext, useEditable } from '@dltru/dfa-ui'

import { EmissionCardPageContext } from '@pages/DFAEmission/DFAEmissionCardPage/EmissionCardPageContext'

import { UploadInput } from '../../Form'


export const IssueAppliedDocs: FC<{ stepIndex: number; isModification: boolean }> = ({
    stepIndex,
    isModification,
}) => {
    const [form] = Form.useForm()
    const { editable, currentStep, stepsTotal, values } = useContext(StepperContext)
    const { setDisabledActionButtons } = useContext(EmissionCardPageContext)

    useEffect(() => {
        form.setFieldsValue(values)
    }, [values])

    const isEdit = useEditable(currentStep, stepIndex, editable)
    const allowEdit =
        isModification &&
        currentStep >= stepsTotal &&
        (!editable.length || editable.includes(stepIndex))

    useEffect(() => {
        setDisabledActionButtons(isEdit)
    }, [isEdit])

    return (
        <Step
            stepIndex={stepIndex}
            form={form}
            stepTitle="Иные документы"
            allowEdit={allowEdit}
            saveAfterEdit
        >
            <UploadInput
                className="formComponentItem form-item--upload"
                name="attachments"
                label="Прилагаемые документы"
                uneditable={isEdit}
                title="Загрузите подписанное Решение о выпуске ЦФА. Допустимый формат файлов JPG, JPEG, TIFF, PDF, PNG, DOC, DOCX, TXT, RTF, ODT. Общий вес не более 25 МБ"
                maxSizeMb={5}
                maxCount={5}
                accept=".jpg, .jpeg, .tiff, .pdf, .png, .doc, .docx, .txt, .rtf, .odt"
            />
        </Step>
    )
}